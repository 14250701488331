import React from "react";
import ProductTemplate from "./product";

// import NewProductTemplate from "../../components/sections/product-more-info/Item";

import Title from "@components/common/Title";
import Text from "@components/common/Text";

import { InlineWidget } from "react-calendly";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    /*const newProductsEnabled =
        process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";*/

    const Data = () => {
        return (
            <>
                {/** Qué es¿? */}
                <div className="container lg:px-0 py-20">
                    <Title>Preparació/Simuacre d'Entrevista (Policia Local 2024 - 90 minuts)</Title>

                    <Text>
                        Vols posar-te a prova amb una simulació/preparació d'entrevista policial amb
                        role play?
                        <br />
                        <br />
                        Recrearem el mateix escenari que el dia de la prova oficial.
                        <br />
                        <br />
                        La nostra preparació és molt personalitzada, amb preguntes extretes de
                        processos policials actuals.
                        <br />
                        <br />
                        Això fa que el nostre mètode sigui únic i el nostre percentatge d'aprovats
                        sigui altíssim.
                        <br />
                        <br />
                        En una entrevista policial estaràs nerviós, assumeix-ho.
                        <br />
                        <br />
                        Des de iOpos t'ensenya a minimitzar l'ansietat anticipatòria.
                        <br />
                        <br />
                        És un procés normal, adaptatiu. Una bona preparació per part del nostre
                        equip t'ajudarà molt.
                        <br />
                        <br />
                        Treballarem tots els aspectes clau que necessitaràs per afrontar-la de la
                        millor manera possible.
                        <br />
                        <br />
                        La metodologia és del tot personalitzada i es posarà èmfasi en les
                        preguntes-respostes més importants en base a cada alumne.
                        <br />
                        <br />
                        En definitiva, t'ajudarem a treballar la teva entrevista personal des d'un
                        punt de vista honest i natural.
                        <br />
                        <br />
                        La preparació consisteix en 1 hora i 30 minuts cara a cara amb un preparador
                        especialitzat que t'analitzarà i et guiarà de la millor manera.
                        <br />
                        <br />
                        45 minuts de simulacre i 45 minuts de preparació.
                        <br />
                        <br />
                        És totalment personalitzada i en base a cada aspirant.
                        <br />
                        <br />
                        Escull dia i hora...
                        <br />
                        <br />
                        T'esperem!
                    </Text>

                    <div id="disponibles"></div>

                    <div className="mt-10 border-t border-b border-gray-300 pt-5 pb-10">
                        {/*<h1 className="font-bold uppercase font-montserrat text-xl text-cpurple">
                        Entrevistes disponibles per Formador i Ubicació
                    </h1>*/}

                        <div className="grid grid-cols-1 gap-10 mt-10">
                            {/** Jordi Psicólogo */}
                            <div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                                <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                    Formador Jordi Psicólogo - C/ Martí Codolar, 18 - Hospitalet de
                                    Llobregat
                                </h1>

                                <h2 className="text-yellow-600 font-montserrat">
                                    Si no ves correctamente el calendario haz clic{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="font-bold"
                                        href="https://calendly.com/iopos-academia-jordi/simulacre-preparacio-d-entrevista-1-30-h"
                                    >
                                        aquí.
                                    </a>
                                </h2>

                                <InlineWidget
                                    styles={{ width: "100%", height: "920px" }}
                                    url="https://calendly.com/iopos-academia-jordi/simulacre-preparacio-d-entrevista-1-30-h/?hide_gdpr_banner=1"
                                />
                            </div>

                            {/** Jonathan */}
                            <div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                                <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                    Formador Jonathan - C/ Martí Codolar, 18 - Hospitalet de
                                    Llobregat
                                </h1>

                                <h2 className="text-yellow-600 font-montserrat">
                                    Si no ves correctamente el calendario haz clic{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="font-bold"
                                        href="https://calendly.com/iopos-academia-jona/simulacre-preparacio-d-entrevista-1-30-h"
                                    >
                                        aquí.
                                    </a>
                                </h2>

                                <InlineWidget
                                    styles={{ width: "100%", height: "920px" }}
                                    url="https://calendly.com/iopos-academia-jona/simulacre-preparacio-d-entrevista-1-30-h/?hide_gdpr_banner=1"
                                />
                            </div>

                            {/** Toni */}
                            {/*<div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                                <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                    Formador Toni - C/ Martí Codolar, 18 -
                                    Hospitalet de Llobregat
                                </h1>

                                <h2 className="text-yellow-600 font-montserrat">
                                    Si no ves correctamente el calendario haz
                                    clic{" "}
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        className="font-bold"
                                        href="https://calendly.com/iopos-academia-toni/simulacre-preparacio-d-entrevista-1-30-h"
                                    >
                                        aquí.
                                    </a>
                                </h2>

                                <InlineWidget
                                    styles={{ width: "100%", height: "920px" }}
                                    url="https://calendly.com/iopos-academia-toni/simulacre-preparacio-d-entrevista-1-30-h?hide_gdpr_banner=1"
                                />
                            </div>*/}

                            {/** Txema */}
                            {/*<div className="rounded-md shadow-xl p-5 border border-gray-400 space-y-5">
                            <h1 className="uppercase font-bold border-b-2 border-gray-400 pb-1 text-gray-600">
                                Formador Txema (Docent) - C/ Martí Codolar, 18 -
                                Hospitalet de Llobregat pment
                            </h1>

                            <h2 className="text-yellow-600 font-montserrat">
                                Si no ves correctamente el calendario haz clic{" "}
                                <a
                                    target="_blank"
                                    rel="noreferrer noopener"
                                    className="font-bold"
                                    href="https://calendly.com/iopos-academia-jose-m/entrevista-avancada-castelldefels"
                                >
                                    aquí.
                                </a>
                            </h2>

                            <InlineWidget
                                styles={{ width: "100%", height: "920px" }}
                                url="https://calendly.com/iopos-academia-jose-m/entrevista-avancada-castelldefels?hide_gdpr_banner=1"
                            />
                        </div>*/}
                        </div>

                        <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                            <iframe
                                title={item.title}
                                width="100%"
                                height="600px"
                                src="https://www.youtube.com/embed/nnlG-L9xGFg"
                                frameBorder={"0"}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <ProductTemplate item={item}>
                <Data />
            </ProductTemplate>
        </>
    );
};

export default Product;
